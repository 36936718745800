module services {
    export module costing {

        export class costParameterPurchaseOrderTypeService implements interfaces.costing.ICostParameterPurchaseOrderTypeService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getPurchaseOrderTypeList(): ng.resource.IResourceClass<interfaces.costing.ICostPurchaseOrderType> {
                return this.$resource<interfaces.costing.ICostPurchaseOrderType>(this.ENV.DSP_URL + "CostParameterPurchaseOrderType/GetPurchaseOrderTypeList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    purchaseOrderTypeId: '@purchaseOrderTypeId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            savePurchaseOrderType(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterPurchaseOrderType/SavePurchaseOrderType", {
                });
            }

            removePurchaseOrderType(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterPurchaseOrderType/RemovePurchaseOrderType", {
                    cpuId: '@cpuId',
                });
            }


        }

    }

    angular.module("app").service("costParameterPurchaseOrderTypeService", services.costing.costParameterPurchaseOrderTypeService);
}